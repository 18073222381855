<template>
  <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
    <van-list class="zdd-record" v-if="recordList.length" @load="onReachBottom">
      <ZddCertificateRecordItem
        v-model="loading"
        v-for="record in recordList"
        :key="record.viewId"
        :is-com="isComUser"
        :item="record"
        :finished="recordList.length >= total"
        finished-text="没有更多了"
      />
    </van-list>
    <div class="no-record" v-else>
      <SvgIcon class="list-no-data-img" type="no_certificate_data" />
      <div class="list-no-data-text">无记录</div>
    </div>
  </van-pull-refresh>
</template>

<script>
  import ZddCertificateRecordItem from '@/components/Zdd/ZddCertificateRecordItem.vue'
  import archivesApi from '@/api/user/archives'

  export default {
    name: 'ZddRecord',
    components: {
      ZddCertificateRecordItem,
    },
    data() {
      return {
        recordList: [],
        getRecordListModel: {
          pageSize: 10,
          pageNum: 1,
          status: null,
        },
        total: 0,
        loading: false,
        getListLoading: false,
        refreshing: false,
      }
    },
    computed: {
      isComUser() {
        return this.$store.getters.isComUser
      },
    },
    // created() {
    //   this.fetchData()
    // },
    activated() {
      this.resetSearchModel()
      this.fetchData()
    },
    methods: {
      async fetchData() {
        await this.getUserViewArchivesList()
      },
      resetSearchModel() {
        this.getRecordListModel.pageNum = 1
      },
      onReachBottom() {
        if (this.recordList.length >= this.total) {
          return
        }
        if (this.getListLoading) return
        const curPageNum = this.getRecordListModel.pageNum
        this.getRecordListModel.pageNum = curPageNum + 1
        this.fetchData()
      },
      async onRefresh() {
        try {
          this.resetSearchModel()
          await this.fetchData()
        } catch (error) {
          console.error(error)
        } finally {
          this.refreshing = false
        }
      },
      async getUserViewArchivesList() {
        let loadingInstance
        try {
          if (this.loading || this.getListLoading) return
          this.getListLoading = true
          const { total, rowsList } = await archivesApi.getUserViewArchivesList(
            this.getRecordListModel
          )
          if (this.getRecordListModel.pageNum > 1) {
            this.recordList = [...this.recordList, ...rowsList]
          } else {
            this.recordList = [...rowsList]
          }
          this.total = total
        } catch (error) {
          console.error(error)
        } finally {
          // loadingInstance.close()
          this.getListLoading = false
        }
      },
    },
  }
</script>

<style scoped lang="less">
  .zdd-record {
    padding: 16px 16px 50px;
  }
  .no-record {
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .list-no-data-img {
      /deep/ svg {
        width: 159px;
        height: 63px;
      }
    }
    .list-no-data-text {
      margin-top: 10px;
      font-size: 14px;
      color: #999ea7;
    }
  }
</style>
